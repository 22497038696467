<template>
  <div class="pl-4">
    <div
      v-for="(versionInner, vindexInner) in getReParsedObject(obj)"
      :key="vindexInner"
      class="my-1 highlighter"
    >
      <span>{{ versionInner.label }}:</span>
      <span
        v-if="
          versionInner.data &&
            (versionInner.data.length || !isNaN(versionInner.data))
        "
        class="grey--text ml-2"
        >{{ versionInner.data }}</span
      >
      <template v-else>
        <SingleObjectView v-if="versionInner.data" :obj="versionInner.data" />
      </template>
    </div>
  </div>
</template>

<script>
import SingleObjectView from "./SingleObjectView";

export default {
  name: "SingleObjectView",
  components: { SingleObjectView },
  props: {
    obj: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    getReParsedObject(item) {
      const parsedVersions = [];
      const versions = Object.entries(item);
      if (versions.length) {
        versions.forEach(version => {
          parsedVersions.push({
            label: version[0],
            data: version[1]
          });
        });
      }
      return parsedVersions;
    }
  }
};
</script>
